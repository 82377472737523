import {lazy, FC, Suspense} from "react"
import {Route, Routes, Navigate} from "react-router-dom"
import {MasterLayout} from "../../_metronic/layout/MasterLayout"
import TopBarProgress from "react-topbar-progress-indicator"
import {getCSSVariableValue} from "../../_metronic/assets/ts/_utils"
import {WithChildren} from "../../_metronic/helpers"

import DemandResultWrapper from "../pages/gestion-des-rendez-vous/demand-result/DemandResultWrapper"

// import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper"
// import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper"

const PrivateRoutes = () => {
  // const UsersPage = lazy(() => import("../modules/apps/user-management/UsersPage"))
  const AppointmentsPage = lazy(() => import("../pages/gestion-des-rendez-vous/DemandsPageWrapper"))
  // const ExamsPageWrapper = lazy(() => import("../pages/exams/ExamsPageWrapper"))
  // const ServicesPageWrapper = lazy(() => import("../pages/services/ServicesPageWrapper"))
  // const ServiceView = lazy(() => import("../pages/services/service-view/ServiceView"))
  // const SpecialitiesPageWrapper = lazy(
  // () => import("../pages/specialities/SpecialitiesPageWrapper")
  // )
  // const SpecialtyView = lazy(() => import("../pages/specialities/specialitie-view/SpecialitieView"))
  // const PendingMessagesView = lazy(() => import("../pages/pending-messages/Chatpage"))
  // const MessagesView = lazy(() => import("../pages/messages/Chatpage"))

  // const PrepView = lazy(() => import("../pages/preparations/PrepsPageWrapper"))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/appointments' />} />
        {/* Pages */}

        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* <Route path='/appointments' element={<GestionDesRendezVous />} /> */}
        {/* <Route path='/appointments/reservations/:id' element={<Reservation />} /> */}
        {/* Lazy Modules */}

        <Route
          path='/*'
          element={
            <SuspensedView>
              <AppointmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='result/*'
          element={
            <SuspensedView>
              <DemandResultWrapper />
            </SuspensedView>
          }
        />

        {/* Pending Messages Pages */}
        {/* <Route
          path='pending-messages'
          element={
            <SuspensedView>
              <PendingMessagesView />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='pending-messages/:id/:name'
          element={
            <SuspensedView>
              <PendingMessagesView />
            </SuspensedView>
          }
        /> */}
        {/* Messages Pages */}

        {/* <Route
          path='messages'
          element={
            <SuspensedView>
              <MessagesView />
            </SuspensedView>
          }
        /> */}

        {/* <Route
          path='messages/:id/:name'
          element={
            <SuspensedView>
              <MessagesView />
            </SuspensedView>
          }
        /> */}

        {/* <Route path='preparations/:id' element={<SuspensedView></SuspensedView>} /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue("--bs-primary")
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
