/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from "./SidebarMenuItem"
// import {SidebarMenuItemWithSub} from "./SidebarMenuItemWithSub"

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem to='/appointments' title='Gestion des rendez-vous' icon='calendar-edit' />

      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='people'
        title='Gestion des utilisateurs'
      /> */}

      {/* <SidebarMenuItem to='/builder' icon='switch' title='Configuration' fontIcon='bi-layers' /> */}

      {/* <SidebarMenuItem to='/specialties' icon='briefcase' title='Spécialités' /> */}
      {/* <SidebarMenuItem to='/services' icon='pulse' title='Services' /> */}
      {/* <SidebarMenuItemWithSub to='/' icon='test-tubes' title='Examens et préparations'>
        <SidebarMenuItem to='/exams' icon='syringe' title='Examens' />
        <SidebarMenuItem to='/preparations' title='Préparations' icon='information' />
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export {SidebarMenuMain}
