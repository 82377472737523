/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react"
import * as Yup from "yup"
import clsx from "clsx"
import {useFormik} from "formik"
import {login} from "../core/_requests"
import {useAuth} from "../core/Auth"
import {AuthModel, UserModel} from "../core/_models"

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Mauvais format d'e-mail")
    .min(3, "Minimum 3 symboles")
    .max(50, "Maximum 50 symboles")
    .required("E-mail est requis"),
  password: Yup.string()
    .min(3, "Minimum 3 symboles")
    .max(50, "Maximum 50 Symboles")
    .required("Le mot de passe est requis"),
})

const initialValues = {
  email: "",
  password: "",
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)

        if (!auth) {
          setSubmitting(false)
          setLoading(false)
          throw new Error("no auth was received")
        }

        if (auth.data.user.role === "operator") {
          saveAuth(auth.data as AuthModel)
          setCurrentUser(auth.data.user as UserModel)
          setSubmitting(false)
          setLoading(false)
        } else {
          setSubmitting(false)
          setLoading(false)
          setStatus("Non autorisé")
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus("The login details are incorrect")
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Se connecter</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Cima Opérateur</div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Pour utiliser ce tableau de bord, vous devez être ajouté par un{" "}
            <strong>administrateur</strong>.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
        <input
          data-cy='email'
          placeholder='E-mail'
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            {"is-invalid": formik.touched.email && formik.errors.email},
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='on'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mot de passe</label>
        <input
          data-cy='password'
          type='password'
          placeholder='Mot de passe'
          autoComplete='on'
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          data-cy='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continuer</span>}
          {loading && (
            <span className='indicator-progress' style={{display: "block"}}>
              S'il vous plaît, attendez...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
