/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react"
import axiosInstance from "../core/Axios"
export function DemandResult({param1, param2}) {
  const [pdfBlob, setPdfBlob] = useState(null)
  const API_URL = process.env.REACT_APP_API_URL
  const Result = `${API_URL}/results`
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axiosInstance.get(`${Result}/${param1}/${param2}`, {
          responseType: "blob",
        })
        setPdfBlob(response.data)
        window.open(URL.createObjectURL(response.data))
      } catch (error) {
        console.error("Error fetching PDF:", error)
      }
    }
    fetchPdf()
  }, [param1, param2])

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{maxWidth: "100%", maxHeight: "100%", width: "90%", height: "90vh"}}>
        {pdfBlob && (
          <embed
            src={URL.createObjectURL(pdfBlob)}
            type='application/pdf'
            width='100%'
            height='100%'
          />
        )}
      </div>
    </div>
  )
}
