import clsx from "clsx"
import {KTIcon} from "../../../helpers"
import {HeaderUserMenu, ThemeModeSwitcher} from "../../../partials"
// import MessagesSwitch from "./messages-switch"
import {getAllPendingMessagesApi} from "../../../../apis"
import {useMutation} from "react-query"
import {useEffect} from "react"
import Pusher from "pusher-js"
import toast, {Toaster} from "react-hot-toast"

const itemClass = "ms-1 ms-md-4"
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"
const userAvatarClass = "symbol-35px"

const Navbar = () => {
  // const [isAlert, setIsAlert] = useState<boolean>(false)

  const {mutate: getAllConversations} = useMutation({
    mutationFn: async () => {
      return await getAllPendingMessagesApi()
    },
    onError: (error) => {
      console.log("Error getting all conversations :", error)
    },
  })

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY as string, {
      cluster: process.env.REACT_APP_PUSHER_HOST as string,
    })
    const channel = pusher.subscribe("patients-messages-channel")
    channel.bind("patient-message", (data: any) => {
      getAllConversations()
      // setIsAlert(true)
      toast.success("Un nouveau message est arrivé", {
        duration: 10000,
        style: {
          width: "auto",
        },
        className: "fs-3",
      })
    })
    return () => {
      pusher.unsubscribe("patients-messages-channel")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='app-navbar flex-shrink-0 '>
      {/* <div className={clsx("app-navbar-item", itemClass)} id='kt_drawer_chat_toggle'>
        <div
          className={clsx("cursor-pointer symbol")}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTIcon iconName='messages' className='fs-1' />
          {isAlert && (
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          )}
        </div>
        <MessagesSwitch />
      </div> */}

      <div className={clsx("app-navbar-item", itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={"fs-1"} />
        </div>
      </div>

      {/* <div className={clsx("app-navbar-item", itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      <div className={clsx("app-navbar-item", itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx("btn-active-light-primary btn-custom")} />
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <KTIcon iconName='user' className='fs-1' />
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
      <Toaster />
    </div>
  )
}

export {Navbar}
